enum AppCategories {
    Belastingen = "belastingen",
    Omgeving = "omgeving",
    Productiviteit = "productiviteit",
    Sociaal = "sociaal",
    ZaakgerichtWerken = "zaakgerichtWerken",
  }

export const getCategoryColor = (category: string) => {
  switch (category) {
    case AppCategories.Belastingen: return'#31679B';
    case AppCategories.Omgeving: return '#006450';
    case AppCategories.Productiviteit: return '#E15B32';
    case AppCategories.Sociaal: return '#F0C952';
    case AppCategories.ZaakgerichtWerken: return '#E59925';
    default: return 'transparent'
  }
}

export const getCategoryName = (category: string) => {
    switch (category) {
      case AppCategories.Belastingen: return'Belastingen';
      case AppCategories.Omgeving: return 'Omgeving';
      case AppCategories.Productiviteit: return 'Productiviteit';
      case AppCategories.Sociaal: return 'Sociaal';
      case AppCategories.ZaakgerichtWerken: return 'Zaakgericht';
      default: return ''
    }
  }