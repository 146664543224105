import { FC } from 'react';
import styles from './footer.module.css';

const Footer: FC = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div className={styles.footer}>
      <img src="/logo.svg" alt="xxllnc" width={80} height={12} />
      <p className={styles.year}>{ currentYear }</p>
    </div>
  );
}

export default Footer;
