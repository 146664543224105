import { useAuth0 } from "@auth0/auth0-react";
import { useState } from 'react';
import { getEnvVariable } from "../../utils/getEnvVariable";
import styles from './login-button.module.css';

const serverUrl = (): string => getEnvVariable('REACT_APP_SERVER_URL', '')

export const LoginButton = () => {
  const [error, setError] = useState<string | null>(null);
  const [email, setEmail] = useState('');
  const [organizations, setOrganizations] = useState<any[]>([]);
  const [selectedOrganization, setSelectedOrganization] = useState('');
  const { loginWithRedirect } = useAuth0();

  const handleLogin = async () => {
    const response = await fetch(`${serverUrl()}/login_info?filter={"emailAddress":"${email}"}`)
      .catch(setError);
    
    if(!response) return
    const organizationData = await response.json();
    setOrganizations(organizationData);
    if (organizationData.length === 1) {
      loginWithRedirect({
        authorizationParams: {
          organization: organizationData[0].auth0Identifier,
          login_hint: email
        },
      })
    } else if (organizationData.length === 0) {
      setError('Het opgegeven emailadres is niet bekend bij ons. Probeer het opnieuw.');
    }
  };

  const handleDropdownLogin = () => {
    if (selectedOrganization) {
      loginWithRedirect({
        authorizationParams: {
          organization: selectedOrganization,
          login_hint: email
        },
      })
    } else {
      setError('Selecteer een organisatie om in te loggen.');
    }
  };

  return (
    <div className={styles.main}>
      <div className={styles.login}>
        <input
          type="email"
          autoComplete={'email'}
          className={styles.input}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="E-mailadres"
        />
        {organizations.length > 1 ? (
          <>
            <select
              className={styles.select}
              value={selectedOrganization}
              onChange={(e) => setSelectedOrganization(e.target.value)}
            >
              <option value="">Selecteer een organisatie</option>
              {organizations.map(org => (
                <option
                  key={org.auth0Identifier}
                  value={org.auth0Identifier}
                >
                  {org.organizationName}
                </option>
              ))}
            </select>
            <button className={styles.button} onClick={handleDropdownLogin}>Inloggen</button>
          </>
        ) : (
          <button className={styles.button} onClick={handleLogin}>Inloggen</button>
        )}
      </div>
      {error && <p className={styles.error}>{error}</p>}
    </div>
  );
};
