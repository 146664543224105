import { useAuth0 } from '@auth0/auth0-react'
import { FC, useCallback, useEffect, useState } from 'react'
import { getEnvVariable } from '../../utils/getEnvVariable'
import AppItem, { AppItemProps } from './app-item'
import styles from './apps.module.css'

const serverUrl = (): string => getEnvVariable('REACT_APP_SERVER_URL', '')

interface State {
  showResult: boolean
  apps: AppItemProps[]
  error: string | null
}

interface AppsProps {
  user: any
}

const Apps: FC<AppsProps> = ({ user }) => {
  const [state, setState] = useState<State>({
    showResult: false,
    apps: [],
    error: null,
  })

  const { isAuthenticated, getAccessTokenSilently, loginWithPopup, getAccessTokenWithPopup } = useAuth0()

  const callApi = useCallback(async () => {
    try {
      const token = await getAccessTokenSilently()

      const response = await fetch(`${serverUrl()}/app_organization_user`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      if (response.status !== 200) return setState((prevState) => ({
        ...prevState,
        error: 'no_apps',
      }))

      const responseData = await response.json()

      setState((prevState) => ({
        ...prevState,
        showResult: true,
        apps: responseData,
      }))
    } catch (error: any) {
      setState((prevState) => ({
        ...prevState,
        error: error.error,
      }))
    }
  }, [getAccessTokenSilently])

  const handleConsent = async () => {
    try {
      await getAccessTokenWithPopup()
      setState((prevState) => ({
        ...prevState,
        error: null,
      }))
    } catch (error: any) {
      setState((prevState) => ({
        ...prevState,
        error: error.error,
      }))
    }

    await callApi()
  }

  const handleLoginAgain = async () => {
    try {
      await loginWithPopup()
      setState((prevState) => ({
        ...prevState,
        error: null,
      }))
    } catch (error: any) {
      setState((prevState) => ({
        ...prevState,
        error: error.error,
      }))
    }

    await callApi()
  }

  const handle = (e: any, fn: () => {}) => {
    e.preventDefault()
    fn()
  }

  useEffect(() => {
    if (isAuthenticated) callApi()
  }, [isAuthenticated, callApi])

  return (
    <>
      {state.error === 'consent_required' && (
        <p>
          Je moet{' '}
          <a
            href='#/'
            className='alert-link'
            onClick={(e) => handle(e, handleConsent)}
          >
            toegang verlenen aan de applicatie.
          </a>
        </p>
      )}

      {state.error === 'login_required' && (
        <p color='warning'>
          Je moet{' '}
          <a
            href='#/'
            className='alert-link'
            onClick={(e) => handle(e, handleLoginAgain)}
          >
            opnieuw inloggen
          </a>
        </p>
      )}

      {state.error === 'no_apps' && (
        <p color='warning'>
          Er was een probleem met het ophalen van de applicaties. Probeer het later nog eens.
        </p>
      )}

      {user && (
        <div className={styles.content}>
          <div>
            {state?.apps?.filter((app) => app.primaryHostname).map((app) => (
              <AppItem key={app.id} {...app} />
            ))}
          </div>
        </div>
      )}
    </>
  )
}

export default Apps
