import { getEnvVariable } from "./utils/getEnvVariable";

const getAudience = (): string => getEnvVariable('REACT_APP_AUTH0_AUDIENCE', '')
const getDomain = (): string => getEnvVariable('REACT_APP_AUTH0_DOMAIN', 'unset')
const getClientId = (): string => getEnvVariable('REACT_APP_AUTH0_CLIENT_ID', 'unset')

export const getConfig = () => ({
  domain: getDomain(),
  clientId: getClientId(),
  audience: getAudience(),
  scope: 'openid profile email eco.user.app:read',
});