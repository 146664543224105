import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useRef, useState } from 'react';
import { getEnvVariable } from "../../utils/getEnvVariable";
import { AccountIcon, DarkIcon, LightIcon } from '../icons/Icons';
import styles from './topBar.module.css';

const managementUrl = (): string => getEnvVariable('REACT_APP_MANAGEMENT_URL', '')

interface TopBarProps {
  isAuthenticated?: boolean;
}

const TopBar: React.FC<TopBarProps> = ({ isAuthenticated = false }) => {
  const { logout } = useAuth0();
  const [darkMode, setDarkMode] = useState<boolean>();
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const savedDarkMode = window.localStorage.getItem('darkMode') === 'true'
    setDarkMode(savedDarkMode);
  }, []);

  useEffect(() => {
    if (darkMode === undefined) {
      return;
    }
    document.documentElement.setAttribute('data-theme', darkMode ? 'dark' : 'light');
    window.localStorage.setItem('darkMode', (darkMode).toString());
  }, [darkMode]);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setDropdownVisible(false);
    }
  };

  const logoutWithRedirect = () =>
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      }
  });

  useEffect(() => {
    if (dropdownVisible) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownVisible]);

  return (
    <div className={styles.topBar}>
      <div className={styles.logo}>
        <img src="/logo.svg" alt="xxllnc" width={79} height={19} />
      </div>
      <div className={styles.icons}>
        <button onClick={toggleDarkMode} className={styles.iconButton}>
          {darkMode ? <DarkIcon /> : <LightIcon />}
        </button>
        { isAuthenticated && <button onClick={toggleDropdown} className={styles.iconButton}>
          <AccountIcon />
        </button>
        }
        {dropdownVisible && (
          <div ref={dropdownRef} className={styles.dropdownMenu}>
            <a href={managementUrl()} className={styles.dropdownItem}>Beheer</a>
            <a href="#/" className={styles.dropdownItem} onClick={logoutWithRedirect}>Logout</a>
          </div>
        )}
      </div>
    </div>
  );
};

export default TopBar;
