import { FC } from 'react';
import { getCategoryColor, getCategoryName } from '../../utils/category';
import { Arrow } from '../icons/Icons';
import styles from './app-item.module.css';

export interface AppItemProps {
  id: string;
  appName: string;
  description: string;
  category: string;
  primaryHostname: string;
  icon: string;
}

const getUrl = (hostname: string) => {
  if (hostname.startsWith('http') || hostname.startsWith('https')) {
    return hostname;
  }
  return `https://${hostname}`;
}

const AppItem: FC<AppItemProps> = ({ appName, description, category, primaryHostname, icon }) => {
  const handleClick = () => {
    window.open(getUrl(primaryHostname), '_blank');
  }

  return (
    <div className={styles.appItem} onClick={handleClick}>
        <div className={styles.appIcon}>
            <img src={`data:image/svg+xml;base64,${icon}`} alt={appName} width={30} height={30} />
        </div>
        <div className={styles.appDetails}>
            <h2 className={styles.appName}>{appName}</h2>
            <p className={styles.appDescription}>{description}</p>
        </div>
        <div className={styles.appCategory}>
            <span className={styles.appCategoryCircle} style={{ backgroundColor: getCategoryColor(category) }} />
            <span>{getCategoryName(category)}</span>
        </div>
        <div className={styles.appActions}>
            <Arrow />
        </div>
    </div>
  )
}

export default AppItem
