import { useAuth0 } from "@auth0/auth0-react";
import styles from './App.module.css';
import Apps from './components/apps/apps';
import { LoginButton } from './components/buttons/login-button';
import Footer from './components/footer/footer';
import Help from './components/help/help';
import TopBar from './components/topBar/topBar';

const App = () => {
  const { user, isAuthenticated, isLoading, error } = useAuth0();

  return (
    <main className={styles.main}>
      <TopBar isAuthenticated={isAuthenticated}/>

      <div className={styles.content}>
        {!isAuthenticated && !isLoading && <>
            <p className={styles.welcome}>Welkom, <br />Log in om jouw applicaties te zien</p>
            <LoginButton />
          </>
        }
      </div>

      {error && <p>{error.message}</p>}

      { isAuthenticated && <div className={styles.content}>
        <div className={styles.welcome}>
          <p>Welkom {user?.nickname || ''},<br /> dit zijn jouw applicaties</p>
        </div>
        <Apps user={user} />
      </div>
      }

      <Help />
      <Footer />

    </main>
  );
}

export default App;